@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "react-datepicker/dist/react-datepicker.css";
@import "react-date-range/dist/styles.css"; // main style file
@import "react-date-range/dist/theme/default.css"; // theme css file
@import "react-vis/dist/style";

@layer base {
  h1 {
    @apply text-2xl font-semibold text-gray-900;
  }

  dt {
    @apply text-sm font-medium text-gray-700 uppercase;
  }

  dd {
    @apply mt-1 text-sm text-gray-900;
  }
}

.primary-link {
  @apply text-indigo-600 hover:text-indigo-900;
}

.secondary-link {
  @apply hover:text-gray-500;
}

.btn-primary {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer;
}

.btn-secondary {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer;
}

.btn-secondary-small {
  @apply inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer;
}

.btn-tertiary {
  @apply inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer;
}

.sidebar-icon {
  @apply text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6;
}

.backoffice-table {
  @apply min-w-full divide-y divide-gray-200;

  & thead {
    @apply bg-gray-50;
  }

  & th {
    @apply px-6 pb-3 pt-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }

  & td {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900;
  }
}

.well {
  @apply bg-white shadow overflow-hidden rounded-lg mb-4;

  & .well-header {
    @apply border-b border-gray-200 p-4 pt-5;
  }

  & .well-header h2 {
    @apply text-lg font-medium text-gray-900;
  }

  & .well-body {
    @apply p-4;
  }
}

.generated-markup {
  & ul {
    @apply list-disc list-inside pb-2;
  }

  & ol {
    @apply list-decimal list-inside pb-2;
  }

  & span.ql-size-small {
    @apply text-xs;
  }

  & div {
    @apply pb-2;
  }
}
